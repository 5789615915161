import React, { useEffect } from 'react';
import s1 from './images/skin3.webp'
import s2 from './images/SHIRODHARA.webp'
import s3 from './images/laser.jpg'
import s4 from './images/SHIRODHARA.webp'
import g1 from './images/Dok masaj.jpeg'
import g2 from './images/Face.jpeg'
import g3 from './images/back  2 masaj.jpeg'
import g4 from './images/face tatto.jpeg'
import g5 from './images/hair treat.jpeg'
import face from './images/hatoda.jpeg';
import b2 from './images/BA leg.jpeg';
import b3 from './images/Face.jpeg';
import b4 from './images/face tatto.jpeg';
import b5 from './images/mos in hair.jpeg';
import b6 from './images/Dok masaj.jpeg';
import './Home.css'
import { Link } from 'react-router-dom';

const images = [face, b6, b3, b4, b5, b2];

const Home = () => {
  useEffect(() => {
    window.$('.popup-youtube').magnificPopup({
      type: 'iframe',
      mainClass: 'mfp-fade',
      removalDelay: 160,
      preloader: false,
      fixedContentPos: false
    });
  }, []);

return (
    <>
     
      <section className="w3l-main-slider banner-slider position-relative" id="home">
        <div className="owl-one owl-carousel owl-theme">
          <div className="item">
            <div className="slider-info banner-view banner-top1">
              <div className="container">
                <div className="banner-info header-hero-19">
                  <h5>Welcome to Cosmoderm Clinic</h5>
                  <h3 className="title-hero-19">Your Premier Destination for Advanced Aesthetic Care</h3>
                </div>
                <div className="banner-info header-hero-19 banner-info2 header-hero-192">
                  <h5>Rejuvenate and Revitalize</h5>
                  <h3 className="title-hero-19">State-of-the-Art Treatments for Radiant Skin</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="scroll-button">
          <Link to="about" className="scroll">
            <div className="arrow"></div>
          </Link>
        </div>
      </section>

    
      <div className="w3l-3-grids py-5" id="grids-3">
        <div className="container py-md-5 py-2">
          <div className="title-content text-center">
            <h6 className="title-subw3hny mb-1">About Us</h6>
            <h3 className="title-w3l">Popular Services</h3>
            <p className="text-para mb-4">Leading the Way in Advanced Aesthetic Treatments</p>
          </div>
          <div className="row pt-lg-4 mt-5 top-grids3-info">
            <div className="col-lg-3 col-md-6 mt-md-0 mt-sm-4">
              <div className="grids3-info position-relative">
                <a href="#" className="d-block zoom">
                  <img src={s1} alt="Skin Treatment" className="img-fluid news-image" />
                </a>
                <div className="w3-grids3-info"></div>
              </div>
              <h4 className="text-center mt-4">
                <a href="#">Skin Treatment</a>
              </h4>
              <p className="text-center mb-3">Rejuvenate your skin with our personalized Ayurvedic therapies tailored to address your unique skin concerns.</p>
              <a className="w3item-link" href="services">
                <i className="fas fa-arrow-right"></i>
              </a>
            </div>
            <div className="col-lg-3 col-md-6 mt-md-0 mt-4 grids3-info2">
              <div className="grids3-info second position-relative">
                <a href="#" className="d-block zoom">
                  <img src={s2} alt="Hair Treatment" className="img-fluid news-image" />
                </a>
                <div className="w3-grids3-info second"></div>
              </div>
              <h4 className="gdnhy-1 mt-4">
                <a href="#">Hair Treatment</a>
              </h4>
              <p className="text-center mb-3">Nourish your hair with our natural treatments that promote growth, reduce hair fall, and restore shine.</p>
              <a className="w3item-link" href="services">
                <i className="fas fa-arrow-right"></i>
              </a>
            </div>
            <div className="col-lg-3 col-md-6 mt-lg-0 mt-4">
              <div className="grids3-info position-relative">
                <a href="#" className="d-block zoom">
                  <img src={s3} alt="Laser Treatment" className="img-fluid news-image" />
                </a>
                <div className="w3-grids3-info"></div>
              </div>
              <h4 className="gdnhy-1 mt-4">
                <a href="#">Laser Treatment</a>
              </h4>
              <p className="text-center mb-3">Experience the precision of our advanced laser treatments, complemented by Ayurvedic post-care for optimal results.</p>
              <a className="w3item-link" href="services">
                <i className="fas fa-arrow-right"></i>
              </a>
            </div>
            <div className="col-lg-3 col-md-6 mt-lg-0 mt-4">
              <div className="grids3-info position-relative">
                <a href="#" className="d-block zoom">
                  <img src={s4} alt="Panchakarma Treatment" className="img-fluid news-image" />
                </a>
                <div className="w3-grids3-info"></div>
              </div>
              <h4 className="gdnhy-1 mt-4">
                <a href="#">Panchakarma</a>
              </h4>
              <p className="text-center mb-3">Detoxify and revitalize your body with Panchakarma, a comprehensive Ayurvedic cleansing therapy.</p>
              <a className="w3item-link" href="services">
                <i className="fas fa-arrow-right"></i>
              </a>
            </div>
          </div>
        </div>
      </div>


  
   <section className="w3l-video-sec py-5" id="video">
  <div className="container py-md-5">
    <div className="row">
      <div className="col-lg-7 w3l-index5 py-5">
        <div className="w3video-bghny py-5">
          <div className="history-info position-relative py-5 my-lg-5">
            <a
              href="https://www.youtube.com/watch?v=GDxZa6x3Tm4"
              className="popup-youtube play-view text-center position-absolute"
            >
              <span className="video-play-icon">
                <span className="fa fa-play"></span>
              </span>
            </a>
          </div>
        </div>
      </div>
      <div className="col-lg-5 w3l-video-info-right ps-lg-5 mt-lg-0 mt-5">
        <div className="title-content-two">
          <h6 className="title-subw3hny">Popular Services</h6>
          <h3 className="title-w3l">Experience Beauty at Cosmoderic Clinic</h3>
          <p className="mt-2">
            At Cosmoderic, we offer a range of advanced aesthetic treatments designed to enhance your natural beauty and provide a rejuvenating experience. Our expert team uses cutting-edge technology and personalized care to ensure you achieve the best possible results. Whether you're looking for skincare treatments, anti-aging solutions, or holistic wellness therapies, Cosmoderic is your trusted partner in beauty and well-being.
          </p>
          <a href="services" className="btn btn-style btn-primary mt-lg-5 mt-4">Our Services</a>
        </div>
      </div>
    </div>
  </div>
</section>



      <section className="w3l-gallery" id="gallery">
        <div className="destionation-innf py-5">
          <div className="container py-lg-5 py-md-4 py-2 HomePageGallery">
            <div className="title-content text-center">
              <h6 className="title-subw3hny text-center">Gallery</h6>
              <h3 className="title-w3l mb-5 text-center">Photo Gallery</h3>
            </div>
            <ul className="gallery_agile">
              <li>
                <div className="w3_agile_portfolio_grid">
                  <a href="#">
                    <img src={g1} alt=" " className="img-fluid radius-image" />
                    <div className="portfolio-info">
                      <h4>Gallery 1</h4>
                      <i className="fas fa-plus details-link"></i>
                    </div>
                  </a>
                </div>
                <div className="w3_agile_portfolio_grid mt-4">
                  <a href="#">
                    <img src={g2} alt=" " className="img-fluid radius-image" />
                    <div className="portfolio-info">
                      <h4>Gallery 2</h4>
                      <i className="fas fa-plus details-link"></i>
                    </div>
                  </a>
                </div>
              </li>
              <li>
                <div className="w3_agile_portfolio_grid">
                  <a href="#">
                    <img src={g3} alt=" " className="img-fluid radius-image" />
                    <div className="portfolio-info">
                      <h4>Gallery 3</h4>
                      <i className="fas fa-plus details-link"></i>
                    </div>
                  </a>
                </div>
              </li>
              <li>
                <div className="w3_agile_portfolio_grid">
                  <a href="#">
                    <img src={g4} alt=" " className="img-fluid radius-image" />
                    <div className="portfolio-info">
                      <h4>Gallery 4</h4>
                      <i className="fas fa-plus details-link"></i>
                    </div>
                  </a>
                </div>
                
                
                <div className="w3_agile_portfolio_grid mt-4">
                  <a href="#">
                    <img src={g5} alt=" " className="img-fluid radius-image" />
                    <div className="portfolio-info">
                      <h4>Gallery 5</h4>
                      <i className="fas fa-plus details-link"></i>
                    </div>
                  </a>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </section>



     

      <section className="w3-midhny-sec py-5" id="mid">
        <div className="container py-lg-5">
          <div className="w3-midhny-info">
            <div className="row">
              <div className="col-lg-7">
                <div className="bottom-info">
                  <div className="header-section title-content-two pe-lg-5">
                    <h5 className="title-subw3hny mb-3">Our Special Theorpy</h5>
                    <h3 className="title-w3l two mb-4">An Incredible Treatment <br />Experience</h3>
                  </div>
                </div>
              </div>
              <div className="col-lg-5 align-self mt-lg-0 mt-sm-5 mt-4">
                <div className="w3l-buttons d-sm-flex justify-content-end">
                  <Link to="Contact" className="btn btn-style btn-white btn-primary">
                    Make an appointment
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="w3l-subscribe-content py-5">
        <div className="container py-md-4 py-3 text-center">
          <div className="row">
            <div className="col-lg-9 col-md-10 mx-auto">
              <div className="subscribe mx-auto">
                <div className="header-section text-center mx-auto">
                  <h6 className="title-subw3hny mb-2">Join Us</h6>
                  <h3 className="title-w3l">Stay Updated!</h3>
                  <p className="my-3 px-lg-5">
                    We appreciate your trust greatly! Our clients choose us and our Treatment because they know we’re the best.
                  </p>
                </div>
               
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="instagram-grid-wrap-top">
      <div className="container-fluid mx-lg-0">
        <div className="row footer-top-29 instagram-grid-wrap px-0">
          {images.map((img, index) => (
            <div key={index} className="col-lg-2 col-4 mt-lg-0 mt-4 instagram-grid-wrap-grid px-0">
              <div className="item-grid grid-style-1">
                <div className="img-thumb">
                  <a href="#">
                    <img src={img} alt="" className="img-fluid" />
                  </a>
                  <div className="img-thumb-item-info">
                    <div className="inner">
                      <a href="#"><span className="far fa-heart"></span>{Math.floor(Math.random() * 5) + 1}k</a>
                      <a href="#"><span className="far fa-comment"></span>{Math.floor(Math.random() * 10)}</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>

      
    </>
  );
};

export default Home;
